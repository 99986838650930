import React, { useCallback, useEffect, useState } from 'react';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
import Badge from 'react-bootstrap/Badge';
import Form from 'react-bootstrap/Form';
import InputGroup from 'react-bootstrap/InputGroup';
import Pagination from 'react-bootstrap/Pagination';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faCalendarTimes,
  faEdit,
  faTimes,
} from '@fortawesome/free-solid-svg-icons';
import PageSpinner from '../PageSpinner/PageSpinner';
import CustomTable from '../CustomTable/CustomTable';
import PageSubtitle from '../PageSubtitle/PageSubtitle';
import { loadRequests } from '../../utilities/requests';
import dayjs from 'dayjs';
import customParseFormat from 'dayjs/plugin/customParseFormat';

import './Requests.css';

import EditDeliveryModal from './EditDeliveryModal';

dayjs.extend(customParseFormat);

const getPrice = ({ PricingInfoRequest }) => {
  return PricingInfoRequest?.price;
};

const Requests = () => {
  const { t } = useTranslation();
  const history = useHistory();

  const [requests, setRequests] = useState(null);
  const [loadedCount, setLoadedCount] = useState(0);
  const [xTotal, setXTotal] = useState(0);
  const [page, setPage] = useState(1);
  const [perPage] = useState(20);
  const [pages, setPages] = useState([1]);
  const [stagedFilterText, setStagedFilterText] = useState('');
  const [filterText, setFilterText] = useState('');

  useEffect(() => {
    document.title = `${t('requests')} | Stuart XL`;
  });

  function range(start, end) {
    if (!end) return [1];
    return Array.apply(0, Array(end)).map((element, index) => index + start);
  }

  const getStatusBadge = (request) => {
    if (request.Status === 'cancelled') {
      return <Badge variant='danger'> {t('cancelled')}</Badge>;
    }
    if (request.Status === 'validation') {
      return (
        <Badge variant='secondary'>
          {t(
            request.PaymentIntent
              ? 'waiting_for_validation'
              : 'waiting_for_validation_no_payment'
          )}
        </Badge>
      );
    }

    return (
      <Badge variant='success'>
        {' '}
        {t('validated')} ({t(request.Job?.status)})
      </Badge>
    );
  };

  const reloadRequests = useCallback(
    async (p) => {
      setRequests(null);
      const args = [p, perPage, filterText];
      const { requests: loadedRequests = [], total } = await loadRequests(
        ...args
      );

      setXTotal(total);
      setRequests(loadedRequests);
      setLoadedCount(loadedRequests.length);
      setPages(range(1, Math.ceil(total / perPage)));
    },
    [perPage, filterText]
  );

  useEffect(() => {
    reloadRequests(page);
  }, [reloadRequests, page]);

  const open = (id) => () => {
    history.push(`/requests/${id}`);
  };

  const submitFilter = (e) => {
    e.preventDefault();
    setFilterText(stagedFilterText);
    setPage(1);
  };

  const clearFilter = (e) => {
    setStagedFilterText('');
    setFilterText('');
    setPage(1);
  };

  const getPickUpAddress = (request) => {
    if (request.Channel === 'instore') {
      return request.Store;
    }

    return request.JobReques?.pickups[0]?.address;
  };

  const getRequestDate = (request) => {
    if (request.Channel === 'instore') {
      return request.JobRequest.dropoff_at;
    }

    return request?.JobRequest?.pickup_at;
  };

  const getUserContact = (request) => {
    if (request.UserContact) {
      return {
        email: request.UserContact.email,
        phone: request.UserContact.phone,
      };
    }

    return {
      email: request.JobRequest?.dropoffs[0].contact.email,
      phone: request.JobRequest?.dropoffs[0].contact.phone,
    };
  };

  const getDOAddress = (request) => {
    let doAddress = request.JobRequest?.dropoffs[0]?.address;

    const lenReq = request.JobRequest?.dropoffs?.length ?? 0;
    if (lenReq > 1) {
      doAddress += ` (+${lenReq - 1} en plus)`;
    }
    return doAddress;
  };

  const [showEditDeliveryModal, setShowEditDeliveryModal] = useState(false);
  const [requestId, setRequestId] = useState(null);

  const openEditDeliveryModal = (id) => {
    setShowEditDeliveryModal(true);
    setRequestId(id);
  };

  return (
    <Container fluid>
      {!requests && <PageSpinner></PageSpinner>}
      {requests && !loadedCount && !filterText.length && (
        <div className='requests-empty'>
          <img src='no-requests.svg' alt='no requests' />
          <h3>{t('noRequestsTitle')}</h3>
          <p>{t('noRequestsDescription')}</p>
        </div>
      )}
      {requests && (loadedCount || filterText.length > 0) && (
        <div>
          <PageSubtitle>
            <h6>
              {xTotal} {t('delivery', { count: xTotal })}
            </h6>
          </PageSubtitle>
          <br />
          <Form onSubmit={submitFilter}>
            <Row>
              <Col xs={{ offset: '1', span: 'auto' }}>
                <InputGroup>
                  <Form.Control
                    id='store'
                    type='text'
                    placeholder={t('filterByStore')}
                    value={stagedFilterText}
                    onChange={(e) => {
                      setStagedFilterText(e.target.value);
                    }}
                  />
                  <InputGroup.Append className='Requests-clearicon'>
                    <InputGroup.Text
                      onClick={clearFilter}
                      disabled={!stagedFilterText.length}
                    >
                      <FontAwesomeIcon icon={faTimes} />
                    </InputGroup.Text>
                  </InputGroup.Append>
                </InputGroup>
              </Col>
              <Col>
                <Button variant='info' onClick={submitFilter}>
                  {' '}
                  {t('filter')}{' '}
                </Button>
              </Col>
            </Row>
          </Form>
          <br />
          <Row>
            <Col xs={{ span: 10, offset: 1 }}>
              <CustomTable>
                <thead>
                  <tr>
                    <th>{t('channel')}</th>
                    <th>N° de commande</th>
                    <th>{t('deliveryDate')}</th>
                    <th>{t('deliveryAddress')}</th>
                    <th>{t('phone')}</th>
                    <th>{t('email')}</th>
                    <th>{t('price')} €</th>
                    <th>{t('status')}</th>
                    <th></th>
                  </tr>
                </thead>
                {requests.length === 0 && (
                  <tbody>
                    <tr>
                      <td colSpan='9' className='emptyRow'>
                        {t('noRequestsSearchResult')}
                      </td>
                    </tr>
                  </tbody>
                )}
                <tbody>
                  {requests.map((request, index) => (
                    <tr key={index} className='Requests-item'>
                      <td>{t(request.Channel)}</td>
                      <td>{request.Job.deliveries[0].client_reference}</td>
                      <td>
                        {dayjs(getRequestDate(request)).format(
                          'DD/MM/YYYY HH:mm'
                        )}
                      </td>
                      <td>{getDOAddress(request)}</td>
                      <td>{getUserContact(request).phone}</td>
                      <td>{getUserContact(request).email}</td>
                      <td>{getPrice(request)?.price_tax_included}</td>
                      <td> {getStatusBadge(request)}</td>
                      <td>
                        <FontAwesomeIcon
                          onClick={() => openEditDeliveryModal(request.ID)}
                          icon={faCalendarTimes}
                          className='editIcon'
                        ></FontAwesomeIcon>
                        <FontAwesomeIcon
                          icon={faEdit}
                          className='editIcon'
                          onClick={open(request.ID)}
                        />
                      </td>
                    </tr>
                  ))}
                </tbody>
              </CustomTable>
            </Col>
          </Row>
          <br />
          <Row>
            <Col xs={1}></Col>
            <Col xs='auto'>
              <Pagination size='sm'>
                {pages.map((value, index) => {
                  return (
                    <Pagination.Item
                      active={page === value}
                      key={index}
                      onClick={(e) => {
                        setPage(parseInt(e.target.text));
                      }}
                    >
                      {value}{' '}
                    </Pagination.Item>
                  );
                })}
              </Pagination>
            </Col>
          </Row>
          <br />
        </div>
      )}
      {showEditDeliveryModal && (
        <EditDeliveryModal
          show={showEditDeliveryModal}
          onHide={() => setShowEditDeliveryModal(false)}
          requestId={requestId}
        />
      )}
    </Container>
  );
};

Requests.defaultProps = {};

export default Requests;
